import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/functions'
import 'firebase/compat/database'

import config from './firebase.config'

firebase.initializeApp(config)

export const auth = firebase.auth()
export const functions = firebase.app().functions('asia-northeast1')
export const database = firebase.database()

export default firebase
