<template>
  <v-app>
    <v-content>
      <v-container v-if="loading" fill-height>
        <v-layout justify-center align-center>
          <p v-if="error" class="red--text">{{ error }}</p>
          <v-progress-circular v-else indeterminate :size="70" :width="7"></v-progress-circular>
        </v-layout>
      </v-container>
      <router-view v-else :user-id="userId"/>
    </v-content>
  </v-app>
</template>

<script>
import { auth, functions } from './firebase.js'
import liff from '@line/liff'

export default {
  name: 'App',
  data () {
    return {
      loading: true,
      error: '',
      userId: null
    }
  },
  beforeCreate () {
    liff.init({
      liffId: '1653760740-ANog9vjk'
    }).then(() => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          this.loading = false
          this.userId = user.uid
        } else {
          this.loading = true
          this.userId = null
          if (liff.isLoggedIn()) {
            const signInWithLINEToken = functions.httpsCallable('signInWithLINEToken')
            signInWithLINEToken({ accessToken: liff.getAccessToken() }).then((response) => {
              auth.signInWithCustomToken(response.data.accessToken)
            }).catch((error) => {
              this.error = 'Auth: ' + error.message
            })
          } else {
            liff.login({
              redirectUri: window.location.origin
            })
          }
        }
      })
    }).catch((error) => {
      this.loading = true
      this.userId = null
      this.error = 'LIFF: ' + error.message
      console.error('LIFF', error.code, error)
    })
  }
}
</script>
