export const config = {
  apiKey: "AIzaSyALHccsf2l_RaJzn9mBMLUfcHV4q9JEDY0",
  authDomain: "werewolf.noter.dev",
  databaseURL: "https://werewolf-2.firebaseio.com",
  projectId: "werewolf-2",
  storageBucket: "werewolf-2.appspot.com",
  messagingSenderId: "445875813875",
  appId: "1:445875813875:web:feb6a7a1b5a45aa9"
}

export default config
